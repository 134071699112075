<template>
  <div>
    <div class="main-p main-p6" v-show="true">
      <p class="tit hidden-sm-and-down">Organizers Certification</p>
      <div style="position: relative;">
        <div class="mask" v-show="shtc">The information cannot be changed while pending, thanks for your patience!</div>
        <p class="p6-der">
          Please fill out the following information to complete verification. After the certification, the organizer's profile photo, brief introduction and other information will appear on the event page, and the logo will be replaced.
        </p>
        <div class="p6-form">
          <el-form :model="funlist" :rules="P6rules" ref="p6ruleForm" label-position="left" label-width="2.7777rem" class="demo-ruleForm">
            <el-form-item label="Organizer's profile photo" prop="tx" class="tx-form" ref="txupload">
              <el-upload
                class="avatar-uploader tx-upload"
                action="/FH-WEB/web/savefile.do"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="funlist.tx" :src="funlist.tx" class="avatar" />
                <i v-else class="el-icon-upload avatar-uploader-icon"><div class="el-upload__text">click "Upload"</div></i>
              </el-upload>
              <p class="tx-upload-tip">Pictures smaller than 2M(JPG, PNG) with recommended size of 360*360px</p>
            </el-form-item>
            <div class="p6-item-fra">
              <el-form-item label="Organization Name (Chinese)" prop="zhname">
                <el-input v-model="funlist.zhname" placeholder="No more than 24 words" maxlength="24" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="Organization Type" prop="organtype">
                <el-select clearable v-model="p6setvalue" placeholder="Please select" class="p6-select">
                  <el-option v-for="item in p6setoptions" :key="item.TWO" :label="item.TWO" :value="item.TWO"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item label="Organization Name (English)" prop="enname">
              <el-input v-model="funlist.enname" placeholder="Less than 140 characters" maxlength="140" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="Organization Description" prop="der">
              <el-input type="textarea" :rows="5" resize="none" v-model="funlist.der" placeholder="No more than 220 words" maxlength="220" show-word-limit></el-input>
            </el-form-item>
            <!-- <el-form-item label="Business license" prop="business">
              <el-upload
                class="upload-demo"
                drag
                action="/FH-WEB/web/savefile.do"
                multiple
                list-type="picture"
                :on-success="handlebusinessSuccess"
                :before-upload="beforebusinessUpload"
                :on-change="checkfilelist"
                :on-remove="checkfilelist"
                :limit="2"
                :file-list="filelist"
                :on-exceed="overLimit"
                ref="busupload"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  click "Upload"
                  <br />
                  Pictures smaller than 4M(JPG, PNG)
                </div>
              </el-upload>
              <p class="business-tip">
                社会团体上传登记证书，企业上传营业执照，若办理过三证合一的企业，请上传最新的营业执照。格式要求：原件照片／扫描件或复印件加盖企业公章后的扫描件，
                <b>最多可上传2张</b>
              </p>
            </el-form-item> -->
            <el-form-item label="Person to Contact" prop="contact"><el-input v-model="funlist.contact" maxlength="50" show-word-limit></el-input></el-form-item>
            <el-form-item label="Phone Number" prop="phone">
              <el-input
                onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                v-model="funlist.phone"
                placeholder="Only number accepted. Remove the “-” from the area code before input"
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="Title for the Person to Contact" prop="position"><el-input v-model="funlist.position" maxlength="50" show-word-limit></el-input></el-form-item>
            <el-form-item label="Address" prop="side"><el-input v-model="funlist.side" maxlength="50" show-word-limit></el-input></el-form-item>
            <el-form-item label="WeChat Public Account ID" prop="wxid" class="clear-indent"><el-input v-model="funlist.wxid" maxlength="50" show-word-limit></el-input></el-form-item>
          </el-form>
        </div>
        <div class="form-sub">
          <div class="form-sub-btn" @click="p6submit()">Submit</div>
          <!-- <div class="check">
          <el-checkbox v-model="checked">
            <span class="c-p1">我已阅读</span>
            <span class="c-p2">Cityplus趣活动Organizers certification协议</span>
          </el-checkbox>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sponsoratttest, savefile, listrzsp, jgtypelist, checkshpass } from '../../api'
import qs from 'qs'

export default {
  name: 'cityG6',
  data () {
    const _this = this
    // var checkbusiness = (rule, value, callback) => {
    //   if (!_this.funlist.business) {
    //     callback(new Error('请上传图片'))
    //   } else {
    //     callback()
    //   }
    // }
    var checkorgantype = (rule, value, callback) => {
      if (_this.p6setvalue == '') {
        callback(new Error('Please select organization type'))
      } else {
        callback()
      }
    }
    return {
      checked: true,
      shtc: false,
      state: '',
      funlist: {
        tx: '',
        zhname: '',
        enname: '',
        der: '',
        business: '',
        contact: '',
        phone: '',
        position: '',
        side: '',
        wxid: ''
      },
      filelist: [],
      p6setoptions: [],
      p6setvalue: '',
      P6rules: {
        tx: [{ required: true, message: 'Please upload image ', trigger: ['blur', 'change'] }],
        zhname: [{ required: true, message: 'Please enter the organization name in Chinese', trigger: 'blur' }, { min: 0, max: 24, message: ' No more than 24 words', trigger: 'blur' }],
        organtype: [{ required: true, validator: checkorgantype }],
        enname: [{ required: true, message: 'Please enter the organization name in English ', trigger: 'blur' }, { min: 0, max: 140, message: 'Less than 140 characters', trigger: 'blur' }],
        der: [{ required: true, message: 'Please enter organization description', trigger: 'blur' }, { min: 0, max: 220, message: ' no more than 220 words', trigger: 'blur' }],
        // business: [{ required: true, message: '请上传图片', validator: checkbusiness }],
        contact: [{ required: true, message: 'Please enter the contact person of organization ', trigger: 'blur' }],
        phone: [{ required: true, message: 'Please enter the  contact number of the organization', trigger: 'blur' }],
        position: [{ required: true, message: 'Please enter the position of contact person ', trigger: 'blur' }],
        side: [{ required: true, message: 'Please enter the organization address ', trigger: 'blur' }],
        wxid: [{ required: false, message: 'Please enter WeChat Official Account ID', trigger: 'blur' }]
      }
    }
  },
  inject: ['reload'],
  watch: {
    state (newValue, oldValue) {
      if (this.state == '1') {
        this.shtc = true
      } else if (this.state == 'true') {
        this.$message({
          showClose: true,
          message: 'Approved! Information updated. ',
          type: 'success',
          center: true
        })
      } else {
        this.$message({
          showClose: true,
          message: 'Approval failed. Please try again. ',
          type: 'error',
          center: true
        })
      }
    }
  },
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // let userInfo = {
    //   userId: '6565656'
    // }
    if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
      this.$confirm('To log in, please click the button below', 'Tips', {
        confirmButtonText: 'Confirm',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        if (sc.isSZSMT()) {
          console.log('在i深圳平台')
          this.$parent.linkIshenzhen()
        } else {
          console.log('不在i深圳平台内')
          this.$router.push('/LoginEN')
        }
      })
    } else {
      checkshpass(userInfo.userId).then(res => {
        if (JSON.stringify(res.data) == '[]') {
          if (this.funlist.tx != '') {
            this.state = 'true'
          }
        } else {
          this.state = res.data[0].STATE
        }
      })
      listrzsp(userInfo.userId).then(res => {
        if (JSON.stringify(res.data) != '[]') {
          this.funlist = {
            tx: res.data[0].IMAGE,
            zhname: res.data[0].CNNAME,
            enname: res.data[0].ENNAME,
            der: res.data[0].SHORTCONTENT,
            contact: res.data[0].NAME,
            phone: res.data[0].PHONE,
            position: res.data[0].POSITION,
            side: res.data[0].ADDRESS,
            wxid: res.data[0].WECHATIP
          }
          this.p6setvalue = res.data[0].ONE
          // this.filelist = JSON.parse(res.data[0].PERMIT)
          // if (JSON.parse(res.data[0].PERMIT).length > 0) {
          //   this.funlist.business = true
          // } else {
          //   this.funlist.business = false
          // }
        }
      })
      jgtypelist().then(res => {
        this.p6setoptions = res.data
      })
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.funlist.tx = `/FH-WEB/${res.result}`
    },
    beforeAvatarUpload (file) {
      const isTYPE = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isTYPE) {
        this.$message({
          showClose: true,
          message: 'Incorrect format. Please upload a JPG or PNG file',
          type: 'error',
          center: true
        })
      }
      if (!isLt2M) {
        this.$message({
          showClose: true,
          message: 'The image should not be larger than 2MB!',
          type: 'error',
          center: true
        })
      }
      return isTYPE && isLt2M
    },
    handlebusinessSuccess (res, file, fileList) {
      file.url = `/FH-WEB/${res.result}`
    },
    beforebusinessUpload (file) {
      const isTYPE = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt4M = file.size / 1024 / 1024 < 4
      if (!isTYPE) {
        this.$message({
          showClose: true,
          message: 'Incorrect format. Please upload a JPG or PNG file.',
          type: 'error',
          center: true
        })
      }
      if (!isLt4M) {
        this.$message({
          showClose: true,
          message: 'The image must not exceed 4MB!',
          type: 'error',
          center: true
        })
      }
      return isLt4M && isTYPE
    },
    checkfilelist (file, filelist) {
      // if (filelist.length > 0) {
      //   this.funlist.business = true
      // } else {
      //   this.funlist.business = false
      // }
    },
    p6submit () {
      this.$confirm(`Once submit, you will not be allowed to edit. Please check it carefully.`, 'Tips', {
        confirmButtonText: 'Confirm to submit ',
        cancelButtonText: 'Back to check',
        type: 'warning',
        center: true
      })
        .then(() => {
          if (!this.funlist.business) {
            this.$refs.p6ruleForm.clearValidate('business')
          }
          this.$refs.p6ruleForm.validate(valid => {
            if (valid) {
              if (!this.checked) {
                this.$message({
                  showClose: true,
                  message: 'Please first check and agree to the CityPlus user registration agreement',
                  type: 'error',
                  center: true
                })
                return false
              } else {
                let userid = JSON.parse(localStorage.getItem('userInfo')).userId
                // let busimgarr = this.$refs.busupload.uploadFiles
                // console.log(busimgarr)
                // let busimglist = []
                // for (let i = 0, len = busimgarr.length; i < len; i++) {
                //   let url = {
                //     name: busimgarr[i].name,
                //     url: busimgarr[i].response == undefined ? busimgarr[i].url : `/FH-WEB/${busimgarr[i].response.result}`
                //   }
                //   busimglist.push(url)
                // }
                let list = {
                  SPONSOR_ID: userid,
                  IMAGE: this.funlist.tx,
                  CNNAME: this.funlist.zhname,
                  ENNAME: this.funlist.enname,
                  ONE: this.p6setvalue,
                  SHORTCONTENT: this.funlist.der,
                  // PERMIT: JSON.stringify(busimglist),
                  NAME: this.funlist.contact,
                  PHONE: this.funlist.phone,
                  POSITION: this.funlist.position,
                  ADDRESS: this.funlist.side,
                  WECHATIP: this.funlist.wxid
                }
                sponsoratttest(qs.stringify(list)).then(res => {
                  if (res.status == 200) {
                    this.$message({
                      showClose: true,
                      message: 'Upload successfully. Please wait for review!',
                      type: 'success',
                      center: true
                    })
                    setTimeout(() => {
                      this.reload()
                    }, 2000)
                  } else {
                    this.$message({
                      showClose: true,
                      message: 'Network errors. Please refresh the page and try again.',
                      type: 'error',
                      center: true
                    })
                  }
                })
              }
            }
          })
        })
        .catch(() => {})
    },
    overLimit () {
      // this.$message({
      //   showClose: true,
      //   message: '上传数量已上限，如需更改，请点击已上传图片右上角进行删除后上传新图片！',
      //   type: 'error',
      //   center: true
      // })
      this.$confirm('Upload limit has been exceeded. Please delete some images and retry. ', 'Tips', {
        confirmButtonText: 'Confirm',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {})
    }
  }
}
</script>

<style lang="stylus" scoped src="../../assets/css/CityGe.styl">

</style>
<style scoped lang="stylus">
.G-container.en {
  .content-box {
    .main-p{
      &.main-p6{
        position relative
        .mask{
          position absolute
          width 100%
          height 100%
          background #fff
          top 0
          left 0
          display flex
          justify-content center
          padding-top .8rem
          font-size 0.3444rem
          color #000
          z-index 888
        }
        .p6-form{
          .business-tip{
            color #8d8d8d
            line-height 1.4
            position absolute
            top 2.32rem
            left 7.5rem
            padding 0
          }
          .el-upload__text{
            color #8d8d8d
          }
        }
      }
    }
  }
}
@media screen and (max-width: 990px){
  .G-container.en {
    .content-box {
      .main-p{
        &.main-p6{
          padding-bottom .3rem
          .p6-der{
            text-align justify
            color #8d8d8d
            font-size 0.3333rem
          }
          .p6-form{
            .tx-upload-tip{
              width 3.6rem
              line-height 1.4
              top .75rem
              left 2.5rem
              .el-form-item__content{
                width 100%
                margin-left 0 !important
              }
            }
            .tx-upload{
              display flex
            }
            .p6-item-fra{
              .el-form-item{
                &:nth-child(1){
                  width 100%
                }
                &:nth-child(2){
                  width 100%
                  .el-form-item__label{
                    text-align left
                  }
                }
              }
            }
            .el-upload__text{
              font-size 0.3333rem
            }
            .business-tip{
              color #8d8d8d
              line-height 1.4
              position unset
              padding .2rem 0 0 0
            }
            .el-form-item{
              margin-bottom .2rem
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="stylus">
  .G-container.en .content-box .content .main-p.main-p6 .p6-item-fra .el-form-item:nth-child(2) .el-form-item__label{
    line-height 2.4
    justify-content center
  }
  .main-p{
    &.main-p6{
      .el-form--label-left .el-form-item__label{
        display flex
        line-height 1.4
      }
      .clear-indent .el-form-item__label{
        text-indent 0 !important
        padding 0 0 0 .75em
      }
    }
  }
@media screen and (max-width: 990px){
  .G-container.en {
    .content-box{
      .content{
       .main-p{
         &.main-p6{
           .p6-form{
             .tx-form{
               .el-form-item__label{
                 width 3.888rem !important
                 line-height 1.4
               }
             }
             .el-form-item__content{
               width 100%
               margin-left 0 !important
             }
             .el-upload.el-upload--picture{
               width 100%
             }
             .el-upload-dragger{
               width 100%
             }
             .p6-select{
               .el-input__suffix{
                 padding 1rem 0 0 0
               }
             }
             .el-form-item__label{
               width 100% !important
               line-height 3
             }
             .p6-item-fra{
               .el-form-item{
                 &:nth-child(2){
                   .el-form-item__label{
                     text-align left
                     justify-content end !important
                   }
                 }
               }
             }
           }
           .form-sub{
             .form-sub-btn{
               margin-top 1rem
               display block
               font-size 0.4074rem
             }
             .check{
               padding-bottom .4rem
             }
           }
         }
       }
      }
    }
  }
}
</style>
